/* import { userLoged } from "../auth"; */

class NotificationsService {

    constructor(){
        this.URI = `${process.env.REACT_APP_API_URL}/api/notifications`;
        this.TOKEN = localStorage.getItem('token');
    }

    async getAll(){
        const response = await fetch(`${this.URI}`,{
            method:"GET",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
        })
        return await response.json();
    }

    async getByUser(){
        const response = await fetch(`${this.URI}/user`,{
            method:"GET",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
        })
        return await response.json();
    }

    async deleteNotificationUser(notificationId){
        const response = await fetch(`${this.URI}/user`,{
            method:"DELETE",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({notification_id: notificationId})
        })
        return await response.json();
    }

    async addNotificationUser(notificationId){
        const response = await fetch(`${this.URI}/user`,{
            method:"POST",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({notification_id: notificationId})
        })
        return await response.json();
    }

}


export default NotificationsService;