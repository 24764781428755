class CategoriasService{
    constructor(){
        this.URI = `${process.env.REACT_APP_API_URL}/api/categorias`;
        this.TOKEN = localStorage.getItem('token');
    }

    async getCategorias(){
        const response = await fetch(`${this.URI}`,{headers:{'authorization':this.TOKEN}})
        const categorias = await response.json();
        return categorias;
    }
}

export default CategoriasService;