import React from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink} from 'react-router-dom';

import Header from './Header';
import ProtectedRoute from './ProtectedRoute';
import SettingsPerfil from './SettingsPerfil';
import SettingsPassword from './SettingsPassword';
import SettingsNotificaciones from './SettingsNotificaciones';


export default function Settings({match}) {
  return (
    <>
    <Header/>
    <div className="settings-component p-5">
        <Router>
            <div className="row">
                <div className="col-md-2 mb-4 mb-md-0">
                    <h5 className="mb-4 text-cen">Ajustes de usuario</h5>
                    <NavLink title="Perfil" className="periodo d-flex px-3 py-2" to="/settings/cuenta">Perfil</NavLink>
                    <NavLink title="Contraseña" className="periodo d-flex px-3 py-2" to="/settings/password">Contraseña</NavLink>
                    <NavLink title="Notificaciones" className="periodo d-flex px-3 py-2" to="/settings/notificaciones">Notificaciones</NavLink>
                </div>
                <div className="col-md-10">
                    <div className="container px-md-0">
                        <Switch>
                            <ProtectedRoute exact path="/settings/cuenta" component={SettingsPerfil}/>
                            <ProtectedRoute exact path="/settings/password" component={SettingsPassword}/>
                            <ProtectedRoute exact path="/settings/notificaciones" component={SettingsNotificaciones}/>
                            <Route component={SettingsPerfil}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </Router>
    </div>
</>
  )
}

/*


export default class Settings extends Component {
    constructor(){
        super();
    }
    render() {
        const ajuste = (this.props.match)?this.props.match.params.ajuste: undefined;    
        return (
            <>
                <Header/>
                <div className="settings-component p-5">
                    <Router>
                        <div className="row">
                            <div className="col-md-2 mb-4 mb-md-0">
                                <h5 className="mb-4 text-cen">Ajustes de usuario</h5>
                                <NavLink title="Perfil" className="periodo d-flex px-3 py-2" to="/settings/cuenta">Perfil</NavLink>
                                <NavLink title="Contraseña" className="periodo d-flex px-3 py-2" to="/settings/password">Contraseña</NavLink>
                            </div>
                            <div className="col-md-10">
                                <div className="container px-md-0">
                                    <Switch>
                                        <ProtectedRoute exact path="/settings/cuenta" component={SettingsPerfil}/>
                                        <ProtectedRoute exact path="/settings/password" component={SettingsPassword}/>
                                        <Route component={SettingsPerfil}/>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </Router>
                </div>
            </>

        )
    }
}
*/