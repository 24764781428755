import {React, useState} from 'react'
import { connect } from 'react-redux'
import { changePeriodoTitle } from '../actions'
import PeriodoService from '../services/PeriodosService'

const TitlePeriodoForm = (props) => {
    const { periodo, onStopEdit } = props;
    const [title, setTitle ] = useState(periodo.titulo);
    const [errors, setErrors ] = useState();

    const submitTitle = async (e)=>{
        e.preventDefault();
        if(title){
            console.log(title)
            const resp = await new PeriodoService().setTitle(periodo.id,title);
            if(resp){
                props.changePeriodoTitle({id:periodo.id, title});
                stopEditTitle();
            }else{
                if(resp.errors){ setErrors(resp.errors)}
            }
        }
    }

    const stopEditTitle = () =>{
        if(onStopEdit)onStopEdit();
    }

    return (
        <form className="mb-2" onSubmit={submitTitle}>
            <div className="row">
                <div className="col-md-4 col-12"> 
                    <div className="input-group">
                        <input 
                            type="text" className="form-control"  id="form-title" name="title" autoComplete="off" 
                            onChange={(e)=>{setTitle(e.target.value)}} 
                            onBlur={(stopEditTitle)} 
                            value={title}
                        />
                    </div>
                    <small className="text-secondary">Presiona enter para guardar los cambios.</small>
                </div>
            </div>
        </form>
    )
}

const mapDispatchToProps = { changePeriodoTitle }

export default connect(null, mapDispatchToProps)(TitlePeriodoForm)