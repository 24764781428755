import React, { useState } from 'react';
import LandingService from '../services/LandingService';
export default function Newsletter() {
    const [email, SetEmail] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const emailHandler = (e) => {
        setShowAlert(false);
        SetEmail(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.reportValidity()) {
            setLoading(true)
            const resp = await new LandingService().newsletterRegistry(email);
            if (resp.status) {
                setShowAlert(true);
                SetEmail("")
            }
            setLoading(false)
        }
    }


    return <>
        <form className='bg-dark py-5 text-white px-3' onSubmit={handleSubmit}>
            <p className='text-center'>¿Te gustaría recibir actualizaciones y consejos para <strong>controlar bien tus finanzas</strong> en tu correo electrónico?</p>

            {
                showAlert &&
                <div className="row mx-0">
                    <div className="col-md-6 offset-md-3">
                        <div class="alert alert-dark" role="alert">!Gracias por tu apoyo! Pronto estaras recibiendo nuestras actualizaciones y consejos</div>
                    </div>
                </div>
            }

            <div className='d-flex justify-content-center mb-1'>
                <input type="email" className="form-control mr-4" name="email" placeholder='Correo electrónico' style={{ width: 300 }} onChange={emailHandler} required value={email} maxLength={255}/>
                <button className='btn btn-primary' >
                    {loading && <div className="spinner-border spinner-border-sm mr-2" role="status"><span className="sr-only">Loading...</span></div>}
                    ¡Hagamoslo!
                </button>
            </div>

        </form>
    </>;
}
