import React, { Component } from 'react'

export default class PasswordInput extends Component {
    constructor(props){
        super();
        this.state = {
            id: props.id,
            name: props.name,
            value:'',
            show: false,
            placeholder: (props.placeholder)? props.placeholder : ""
        }
    }
    onChange = (e)=>{
        this.setState({...this.state, value: e.target.value})
        console.log("value: ",e.target.value)
        this.props.onChange(e)
    }
    render() {
        return (
            <div className="input-group">
                <input 
                    className="form-control" required={this.props.required}
                    name={this.props.name} id={this.props.id}
                    placeholder={this.state.placeholder}
                    type={this.state.show ? "text": "password"}
                    value={this.state.value}
                    onChange={this.onChange}
                />
                <div className="input-group-append">
                    <button type="button" id={this.state.id} name={this.state.name} className="btn btn-primary" onClick={()=> this.setState({show: !this.state.show}) }><i className={this.state.show ? "fa fa-eye":"fa fa-eye-slash"} aria-hidden="true"></i></button>
                </div>
            </div>
        )
    }
}
