import React, { useEffect, useState } from 'react'
import PasswordInput from './PasswordInput';
import UserService from '../services/UserService';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';

 const SettingsPassword = (props)=> {
    const [currentPassword, setCurrentPassword] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errors, setErrors] = useState({})
    const [snackMsg, setSnackMsg] = useState("false")
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [saving, setSaving] = useState(false)
    const [credentialsType, setCredentialsType] = useState("password")
    const [matchPassword, setMatchPassword] = useState(false)
    const [passwordOk, setPasswordOk] = useState(false)

    useEffect(() => {
        if(password === "" && confirmPassword === ""){
            setPasswordOk(true);
        }else if(password.length < 5 ){
            setErrors({password: "La contraseña debe tener al menos 5 caracteres"})
            setPasswordOk(false);
        }else{
            setPasswordOk(true);
        }
    }, [password, confirmPassword]);
    
    useEffect(() => {
        console.log("validando match password")
        setMatchPassword(password === confirmPassword);
    }, [password,confirmPassword])

    const getCredentialsType = async () =>{
        const resp = await new UserService().credentialsType();
        if(resp.status){
            setCredentialsType(resp.credentialsType);
        }else{
            if(resp.errors){ setErrors(resp.errors); }
        }
    }
    useEffect(() => {
        getCredentialsType();
    }, [])

    const showSuccesMessage = (msg)=>{
        setSnackMsg(msg);
        setSnackBarOpen(true);
    }

    const handleCloseSnack = (e,reason)=>{
        if (reason === 'clickaway') {return;}
        setSnackBarOpen(false)
    }

    const onSubmit = async(e) =>{
        e.preventDefault();
        if(matchPassword && passwordOk){
            setErrors({});
            setSaving(true);
            const resp = await new UserService().updatePassword(currentPassword, password, confirmPassword);
            setErrors({});
            setSaving(false);
            if(resp.status){
                setCredentialsType("password")
                showSuccesMessage(resp.msg + " redirigiendo al inicio de sesión");
                setTimeout(() => {
                    localStorage.removeItem('user');
                    window.location.href = '/login'
                }, 2500);
            }else{
                if(resp.errors){ setErrors(resp.errors); }
            }
        }
    }

    const handleInput = (e)=>{
        switch (e.target.name) {
            case "currentPassword":
                setCurrentPassword(e.target.value);
                break;
            case "password":
                setPassword(e.target.value);
                break;
            case "confirmPassword":
                setConfirmPassword(e.target.value);
                break;
            default:
                break;
        }
        return false;
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-12 col-md-4">
                    <h4>Contraseña</h4>
                    <p className="text-secondary">Al actualizar tu contraseña seras redirigido a la pantalla de inicio de sesión para que puedas iniciar sesión con tu nueva contraseña.</p>
                </div>
                <div className="col-12 col-md-7 my-md-4">
                { 
                    (credentialsType && credentialsType == "password") ?
                        <div className="form-group">
                            <label htmlFor="name">Contraseña actual </label>
                            <PasswordInput onChange={ handleInput } name='currentPassword' id='currentPassword' required={true} placeholder="Contraseña actual"></PasswordInput>
                            { errors && errors.currentPassword && (<small style={{display:"block"}} className="text-danger">{errors.currentPassword}</small>)}
                            <small className="text-secondary">Al menos 5 caracteres. Te recomendamos usar una combinación de numeros, mayusculas y minusculas.</small>
                        </div>
                        : 
                        <>
                            <p>Tu metodo de inicio es mediante Google.</p>
                            <p className="mb-4">Configura una contraseña si lo necesitas.</p>
                        </>
                }
                    <div className="form-group">
                        <label htmlFor="name">Nueva contraseña</label>
                        <PasswordInput onChange={ handleInput } name='password' id='password' required={true} placeholder="Nueva contraseña"></PasswordInput>
                        { (!passwordOk && password !== '') && errors && errors.password && (<small style={{display:"block"}} className="text-danger">{errors.password}</small>)}
                        <small className="text-secondary">Al menos 5 caracteres. Te recomendamos usar una combinación de numeros, mayusculas y minusculas.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Confirmar nueva contraseña</label>
                        <PasswordInput onChange={ handleInput } name='confirmPassword' id='confirmPassword' required={true} placeholder="Confirmar nueva contraseña" ></PasswordInput>
                        {  errors && errors.confirmPassword && <small className='text-danger'>{ errors.confirmPassword}</small>}
                        { (confirmPassword !== '' || password !== '')? <p style={{fontSize:"10pt", color: matchPassword? "green":"red"}}>{ 
                                (password === confirmPassword)? 'Las contraseñas coinciden' : 'Las contraseñas no coinciden'
                                }</p>:<></>}
                    </div>
                </div>
            </div>
            <div className="row"><div className="col-12 col-md-11"><hr/></div></div>
            <div className="row">
                <div className="col-12 col-md-11 d-flex justify-content-end">
                    <button type="submit" className="btn btn-success">
                    { saving ? <div className="spinner-border spinner-border-sm" role="status"><span className="sr-only">Loading...</span></div> : "Guardar la contraseña" }
                    </button>
                </div>
            </div>
            <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleCloseSnack}>
                <MuiAlert elevation={6} variant="filled"  severity="success">
                    { snackMsg }
                </MuiAlert>
            </Snackbar>
        </form>
        )
}


export default SettingsPassword;