import React from 'react'

export default function DocumentTitle({title}) {
    document.title= `${process.env.REACT_APP_NAME} - ${title}`;

    return (
        <>
        </>
    )
}
