import React, { useState } from 'react'
import { connect } from 'react-redux'
import { updateCuentaNombre } from '../actions'
import CuentaService from '../services/CuentasService'
import LinkDeleteCuenta from './LinkDeleteCuenta'
import { logEvent } from '../firebase'


const CuentaTitleForm = (props)=> {
    const { cuenta } = props;
    const [titleEdited, setTitleEdited] = useState(props.cuenta.nombre)
    const [errors, setErrors] = useState()

    const submitTitle = async (e)=>{
        e.preventDefault();
        if(titleEdited){
            const resp = await new CuentaService().setNombre(cuenta.id,titleEdited);
            if(resp){
                logEvent('cuenta_update_title', {
                    'title_old': cuenta.nombre,
                    'title': titleEdited
                });
                props.updateCuentaNombre({...cuenta, nombre:titleEdited})
                if(props.onStopEditTitle) props.onStopEditTitle();
            }else{
                if(resp.errors){ setErrors(resp.errors); }
            }
        }else{
            return false;
        }
    }

    return (
        <form className="mb-2" onSubmit={submitTitle}>
            <div className="row">
                <div className="col-12"> 
                    <div className="input-group">
                        <input 
                            type="text" id={"nombreCuentaInput"+cuenta.id} name="titleEdited" autoComplete="off" className="form-control form-control-sm text-center" 
                            value={titleEdited}  
                            onChange={(e)=>{setTitleEdited(e.target.value)}}
                        />
                    </div>
                    <small className="text-secondary">Presiona enter para guardar los cambios.</small>
                    <LinkDeleteCuenta getCuentas={props.getCuentas} onClick={props.onStopEditTitle} cuenta_id={cuenta.id} cuenta_nombre={cuenta.nombre}/>
                </div>
            </div>
        </form> 
    )
}

const mapDispatchToProps = { updateCuentaNombre };

export default connect(null,mapDispatchToProps)(CuentaTitleForm);