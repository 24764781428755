import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent as logEventFirebase } from "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyANtnsLVQX5Mcv1Fx2Mj_tBY-zRbP7DnWI",
  authDomain: "finanzas-73a4d.firebaseapp.com",
  databaseURL: "https://finanzas-73a4d-default-rtdb.firebaseio.com",
  projectId: "finanzas-73a4d",
  storageBucket: "finanzas-73a4d.appspot.com",
  messagingSenderId: "342690359519",
  appId: "1:342690359519:web:af73d8b6a89a292b7e616a",
  measurementId: "G-8C5YFJ4H92"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);


export const logEvent = (event, data) => {
  logEventFirebase(analytics, event, data);
}

export default analytics;



