import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { userLoged } from '../auth';
import { Drawer } from 'rsuite';
import PeriodosSidebar from './PeriodosSidebar';
import AvatarMenu from './AvatarMenu'
import chart from '../img/Chart2.svg'
export default class Header extends Component {
    constructor(){
        super()
        this.state = {
            showDrawer : false
        }
    }
    render() {
        const { showDrawer } = this.state;
        return (
            <>
                <Drawer size="xs" placement="left" show={showDrawer} onHide={this.closeDrawer} className="bg-modal-theme" style={{width:"80%"}}>
                    <Drawer.Header style={{margin:"0",paddingTop:'12px',paddingLeft:'15px',height:"55px",background:"var(--primary-color)",fontSize:"1rem"}} className="bg-primary" >
                        <i className="fa fa-bars text-white mr-2" onClick={this.toggleDrawer} ></i> <p className="text-light navbar-brand">{`${process.env.REACT_APP_NAME}`}</p>                 
                    </Drawer.Header>
                    <Drawer.Body style={{margin:"0",height:"100%"}}>
                        <PeriodosSidebar context="header"></PeriodosSidebar>
                    </Drawer.Body>
                </Drawer>


                <nav className="navbar navbar-expand-lg d-flex justify-content-between" style={{fontSize:"1rem"}}>
                    <div>
                        <i className="fa fa-bars text-white d-md-none mr-3" onClick={this.toggleDrawer} ></i>
                        <img src={chart} width={18} height={18} className='img-fluid  mr-2' alt={`${process.env.REACT_APP_NAME} Icon`} />
                        <Link to="/" className="text-light navbar-brand">{`${process.env.REACT_APP_NAME}`}</Link> 
                    </div>
                    {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                    {/* <AvatarMenu className="m-auto"/> */}

                    {/* <div className="collapse navbar-collapse" id="navbarTogglerDemo02"> */}
                        {/* <ul className="navbar-nav mr-auto mt-2 mt-lg-0"> */}
                        {/* </ul> */}
                        {
                            (userLoged)?
                                <div className="form-inline my-lg-0">
                                    <AvatarMenu/>
                                </div>
                                : 
                                <div className="form-inline my-lg-0">
                                    <Link to="/login" className="text-light navbar-brand">Iniciar Sesión</Link>
                                    <Link to="/signup" className="text-light navbar-brand">Registrarme</Link>
                                </div>
                        }
                        
                    {/* </div> */}
                </nav>
            </>
        )
    }

    toggleDrawer = ()=>{
        this.setState({
            showDrawer: !this.state.showDrawer
        });
    }

    closeDrawer = ()=> {
        this.setState({
          showDrawer: false
        });
    }
   
}
