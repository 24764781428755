import React from 'react';
import appStoreSoon from '../img/app-store-soon.png'
import googlePlaySoon from '../img/google-play-soon.png'

export default function Footer() {
    return <footer className="text-secondary text-center pt-4 pb-2" id="footer" style={{ fontSize: '10pt' }}>
        <div className="d-flex  flex-column flex-md-row justify-content-md-between container">
            <div className="d-inline mb-3 mb-0-md">
                <img src={appStoreSoon} className="mr-2" height="35" alt="appStoreSoon" />
                <img src={googlePlaySoon} height="35" alt="googlePlaySoon" />
            </div>
            <p className="my-1 mb-3 mb-0-md">Hecho con <i className="fas fa-heart text-danger"></i> y <i className="fas fa-mug-hot"></i> desde 🇲🇽 </p>
            {/* <a href="/humans.txt" target="_blank" className="mb-3 mb-0-md"><img src="/humanstxt-isolated-blank.gif" className="humansIcon" alt="humans file" /></a> */}
            <p className="my-1">Copyright © 2022 {`${process.env.REACT_APP_NAME}`}</p>
        </div>
    </footer>
        ;
}
