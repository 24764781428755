import React, { useState } from 'react'
import { connect } from 'react-redux';
import { setAvatar } from '../actions'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import UserService from '../services/UserService';

const  BtnDeleteImg = (props)=> {
    const { onShowSnack } = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    
    const showConfirm = (e)=>{
        e.preventDefault();
        setOpenConfirm(true);
    }

    const handleCancel = (e)=>{
        if(props.onClick) props.onClick();
        setOpenConfirm(false);
    }

    const deleteAccount = async ()=>{
        setOpenConfirm(false);
        const resp = await new UserService().deleteImage();
        if(resp){
            let user = JSON.parse(localStorage.getItem('user'));
            localStorage.setItem("user",JSON.stringify({...user, img:""}));
            props.setAvatar("");
            onShowSnack(resp.msg);
        }
    }

    return (
        <>
            <button type="button" className="btn btn-sm btn-outline-danger mt-3 d-block" onClick={showConfirm}>Eliminar imagen</button>
            <Dialog aria-labelledby="confirmation-dialog-title" open={openConfirm} onClose={handleCancel}>
                <DialogTitle id="confirmation-dialog-title" className="text-center">
                    ¿Seguro que desea eliminar su imagen?
                </DialogTitle>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancelar</button>
                    <button type="button" className="btn btn-danger"    onClick={deleteAccount}>Eliminar</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapDispatchToProps = { setAvatar }

export default connect(null,mapDispatchToProps)(BtnDeleteImg);