export const months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
export const monthsAbreviados = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];

export const now = () =>{
    let date = new Date();
    let year =  date.getFullYear();
    let mes =  date.getMonth().toString().padStart(2,"0");
    let dia  =  date.getDate().toString().padStart(2,"0");
    let hora  =  date.getHours().toString().padStart(2,"0");
    let minutos  =  date.getMinutes().toString().padStart(2,"0");
    let segundos  =  date.getSeconds().toString().padStart(2,"0");

    return `${year}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;
}

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
}


export const formatDate = (date) => {
  const dateSplit = date.split("-");
  if(dateSplit.length===3){
    const meses = ['Ene', 'Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
    const year = dateSplit[0].slice(2,4);
    const month = meses[parseInt(dateSplit[1]-1)];
    const day = dateSplit[2];

    return `${day} ${month} ${year}`;
  }else{
    return date;
  }
}

export const formatDateObj = (date) => {
  let year     = date.getFullYear();
  let mes      = date.getMonth().toString().padStart(2,"0");
  let dia      = date.getDate().toString().padStart(2,"0");
  return `${year}-${mes}-${dia}`;
}

export const formatDateObjToHuman = (date) => {
  let year     = date.getFullYear();
  let mes      = date.getMonth();
  let dia      = date.getDate().toString().padStart(2,"0");
  return `${dia} ${monthsAbreviados[mes]} ${year}`;
}
