import React, { Component } from 'react'
import { formatMoney, formatDate } from '../utils'

export default class Movimiento extends Component {
    constructor(props){
        super();
        
    }

    render() {
        const { monto, tipo, fecha, concepto } = this.props.movimiento;
        const bg  = tipo=== "EGRESO" ? "#F5C7C4"  : "#B6E1CD";
        return (
            <div className="card movimiento">
                <div className="card-body" style={{background:bg}}>
                    <div className="row py-1" onClick={this.openMovimiento}>
                        <div className="col-3 pr-0 text-center">{ formatDate(fecha) }</div>
                        <div className="col-4 text-right">{ formatMoney(monto) }</div>
                        <div className="col-5 text-center">{ concepto }</div>
                    </div>
                </div>
            </div>
        )
    }

    openMovimiento = ()=>{
        this.props.openMovimiento(this.props.movimiento)
    }
}