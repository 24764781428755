import React, { useState } from 'react'

import CategoriasService from '../services/CategoriasService'
import { logEvent } from '../firebase'

const MovimientoForm = (props)=> {
    const [id, setId] = useState(undefined)
    const [tipo, setTipo] = useState("EGRESO")
    const [monto, setMonto] = useState( 0)
    const [concepto, setConcepto] = useState( "")
    const [categoria, setCategoria] = useState("")
    const [categorias, setCategorias] = useState([])
    const [fecha, setFecha] = useState( "")
    const [editMode, setEditMode] = useState(false)
    
    const inputChange = (e)=>{
        switch (e.target.name) {
            case "tipo":
                setTipo(e.target.value);
                break;
            case "monto":
                setMonto(e.target.value);
                break;
            case "concepto":
                setConcepto(e.target.value);
                break;
            case "fecha":
                setFecha(e.target.value);
                break;
            case "categoria":
                setCategoria(e.target.value);
                break;
            default:
                break;
        }
    }

    const init=()=>{
        const date = new Date();
        getCategorias();
        setTipo("EGRESO");
        setMonto("");
        setCategoria("1");
        setConcepto("");
        setFecha(date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0));
        setEditMode(false);
        logEvent('movimiento_form_init');
    }
    
    const getCategorias = async ()=>{
        const resp = await new CategoriasService().getCategorias();
        setCategorias(resp)
    }
    

    const saveMovimiento = ()=>{
        const newMovimiento = { concepto: concepto, categoria: categoria, fecha:fecha,monto:monto, tipo: tipo } 
        if(editMode){
            props.onEdit(id,newMovimiento);
        }
        else{
            props.onSave(newMovimiento);
        }
    }

    const setEdit = () => {
        if(props.movimientoEdit){
            const { id, monto, concepto, fecha, tipo, categoria_id } = props.movimientoEdit;
            getCategorias();
            setId(id);
            setMonto(monto);
            setConcepto(concepto);
            setTipo(tipo);
            setCategoria((categoria_id==null? "" : categoria_id));
            setFecha(fecha);
            setEditMode(true);
        }
    }

    const deleteMovimiento= ()=>{
        const {id} = props.movimientoEdit;
        props.deleteMovimiento(id)
    }


    return (
        <>
        <p className="addMove" data-toggle="modal" onClick={init} data-target={'#exampleModal'+props.cuenta.id}>+ Añadir movimiento</p>
        <p className="editMove d-none"  onClick={setEdit} data-toggle="modal" data-target={'#exampleModal'+props.cuenta.id}>+ Añadir movimiento</p>

        <div className="modal fade" id={"exampleModal"+props.cuenta.id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-modal-theme">
                        <h5 className="modal-title" id="exampleModalLabel">Añadir movimiento a {props.cuenta.nombre} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" className="text-theme">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body bg-body">
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label htmlFor="tipo"><b>Tipo</b></label>
                                <select name="tipo" id="tipo" className="form-control" value={tipo} onChange={inputChange }>
                                    <option value="INGRESO">Ingreso</option>
                                    <option value="EGRESO">Egreso</option>
                                </select>
                            </div>
                            <div className="col-md-12 mb-2">
                                <label htmlFor="categoria"><b>Categoria</b></label>
                                <select name="categoria" id="categoria" className="form-control" value={categoria} onChange={inputChange }>
                                    <option disabled value=''>Seleccione</option>
                                    {
                                        categorias.map((category)=>{
                                            return <option key={category.id} value={category.id}>{category.nombre}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-12 mb-2">
                                <label htmlFor="monto"><b>Monto</b></label>
                                <input type="number" name="monto" id="" className="form-control" value={monto} onChange={inputChange } placeholder="Monto"/>
                            </div>
                            <div className="col-md-12 mb-2">
                                <label htmlFor="concepto"><b>Concepto</b></label>
                                <input type="text" name="concepto" id="" className="form-control" value={concepto} onChange={inputChange } placeholder="Concepto"/>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="fecha"><b>Fecha</b></label>
                                <input type="date" name="fecha" id="" className="form-control" value={fecha} onChange={inputChange } placeholder="Fecha"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-flex bg-modal-theme">
                        { editMode && <button type="button" onClick={deleteMovimiento} className="btn btn-sm btn-danger mr-auto" title="Eliminar"><i className="fas fa-trash"></i></button>}
                        <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={saveMovimiento}>{editMode ? "Guardar cambios" :  "Guardar"}</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}

export default MovimientoForm;