class PeriodoService {

    constructor(){
        this.URI = `${process.env.REACT_APP_API_URL}/api/periodos`;
        this.TOKEN = localStorage.getItem('token');
    }

    async getPeriodos(user_id){
        const response = await fetch(`${this.URI}/user/${user_id}`,{headers:{'authorization':this.TOKEN}})
        const periodos = await response.json();
        return periodos;
    }
    async getTotal(periodo_id){
        const response = await fetch(`${this.URI}/${periodo_id}/total`,{headers:{'authorization':this.TOKEN}})
        const resp = await response.json();
        return resp;
    }

    async getPeriodo(id){
        const response = await fetch(`${this.URI}/${id}`,{headers:{'authorization':this.TOKEN}})
        const periodo = await response.json();
        return periodo;
    }

    async save(periodo,user_id){
        periodo.usuario_id = user_id;
        const response = await fetch(`${this.URI}/save`,{
                method:"POST",
                headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
                body: JSON.stringify(periodo)
            })
        return await response.json();
    }

    async setTitle(periodo_id, newTitle){
        const response = await fetch(`${this.URI}/update`,{
            method:"PUT",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({id:periodo_id,titulo: newTitle})
        })
        return await response.json();
    }
    async delete(periodo_id){
        const response = await fetch(`${this.URI}`,{
            method:"DELETE",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({periodo_id:periodo_id})
        })
        return await response.json();
    }
}

export default PeriodoService;