import React, { Component, useState } from 'react'
import { connect } from 'react-redux';

const PeriodoForm = (props) => {
    const [titulo, setTitulo] = useState('');

    const onSubmit = (e) =>{
        e.preventDefault();
        if(titulo!==''){
            props.addPeriodo(titulo);
            setTitulo("")
        }
    }
    return (
        <form className="mt-1 d-flex justify-content-between px-2" onSubmit={onSubmit}>
            <input 
                type="text" className="form-control form-control-sm" id="periodoTitulo" name="titulo" 
                autoComplete="off" placeholder="Nuevo grupo" 
                value={titulo} 
                onChange={(e)=>{ setTitulo(e.target.value) }}
            />
            <button type="submit" className="btn btn-sm btn-primary ml-2"><i className="fas fa-plus"></i></button>
        </form>
    )
}

export default connect(null,null)(PeriodoForm);