import React, { useState, useEffect } from 'react'
import Movimiento from './Movimiento'
import CuentaService from '../services/CuentasService'
import { formatMoney} from '../utils'
import MovimientoForm from './MovimientoForm'
import CuentaTitleForm from './CuentaTitleForm'
import { connect } from 'react-redux'
import { logEvent } from '../firebase'
const $ = require( "jquery/dist/jquery");

const Cuenta = (props)=>{
    const { cuenta, periodo } = props;
    const [movimientos, setMovimientos] = useState([])
    const [movimientoEdit, setMovimientoEdit] = useState()
    const [editingTitle, setEditingTitle] = useState(false)
    const [titleEdited, setTitleEdited] = useState("")

    useEffect(() => {
        getMovimientos();
    }, [periodo])

    // METODOS
    const getMovimientos = async ()=>{
        const movimientos = await new CuentaService().getMovimientos(cuenta.id,periodo.id);
        setMovimientos(movimientos)
        if(props.onUpdate) props.onUpdate(periodo.id);
    }

    const total = ()=>{
        return movimientos.reduce((acc,movimiento)=> (movimiento.tipo === "EGRESO") ? acc - movimiento.monto :acc + movimiento.monto,0)
    }

    const saveMovimiento = async (newMovimiento)=>{
        const resp = await new CuentaService().storeMovimiento(cuenta.id,periodo.id,newMovimiento)

        if(resp.status){
            getMovimientos();
            logEvent('movimiento_form_save', newMovimiento);
        }
        $(`#exampleModal${cuenta.id}`).click()
    }

    const editMovimiento = async (movimiento_id,newMovimiento)=>{
        const resp = await new CuentaService().editMovimiento(cuenta.id, periodo.id,movimiento_id,newMovimiento)
        if(resp.status){
            getMovimientos();
            logEvent('movimiento_form_edit', newMovimiento);
        }
        $(`#exampleModal${cuenta.id}`).click()
    }
    
    const deleteMovimiento = async (movimiento_id)=>{
        const resp = await new CuentaService().deleteMovimiento(cuenta.id, movimiento_id)
        if(resp.status){
            getMovimientos();
            logEvent('movimiento_delete',{movimiento_id:movimiento_id});
        }
        $(`#exampleModal${cuenta.id}`).click()
    }

    const openMovimiento = async (movimiento)=>{
        await setMovimientoEdit(movimiento)
        document.querySelector(`[data-target="#exampleModal${cuenta.id}"].editMove`).click();
        logEvent('movimiento_open',{movimiento_id:movimiento});

    }

    const startEditTitle =async ()=>{
        setEditingTitle(true);
        setTitleEdited(cuenta.nombre);
        const periodoTitle = document.getElementById('nombreCuentaInput'+cuenta.id);
        if(periodoTitle)periodoTitle.focus();
    }
    const stopEditTitle = ()=>{
        setEditingTitle(false);
    }

    return (
        <div className="columna" onMouseLeave={stopEditTitle}>
            <div className="columna">
                <div className="card movimiento-title">
                    <div className="card-body text-center">
                        {
                            editingTitle ?
                                <CuentaTitleForm cuenta={{id:cuenta.id,nombre:cuenta.nombre}} onStopEditTitle={stopEditTitle}></CuentaTitleForm>
                                :
                                <span className='d-block'>{ cuenta.nombre } <i className="fas fa-pen edit-icon ml-2" onClick={startEditTitle} style={{verticalAlign:"middle"}}></i></span>
                        }
                        <span className={(total()>=0)? "text-success": "text-danger"}>{formatMoney(total())}</span>
                    </div>
                </div>
                <div className="card movimiento-title">
                    <div className="card-body" >
                        <div className="row">
                            <div className="col-4">Fecha</div>
                            <div className="col-4">Monto</div>
                            <div className="col-4">Concepto</div>
                        </div>
                    </div>
                </div>
                { movimientos.map((movimiento)=>{ return <Movimiento openMovimiento={openMovimiento} deleteMovimiento={deleteMovimiento} key={movimiento.id} movimiento={movimiento}/> })}
                <MovimientoForm cuenta={cuenta} deleteMovimiento={deleteMovimiento} movimientoEdit={movimientoEdit} onEdit={editMovimiento} onSave={saveMovimiento}/>
            </div>
        </div>
    )
    
    

}

export default connect(null,null)(Cuenta);