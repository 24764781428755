import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import chart from '../img/Chart2.svg'

export default class HeaderSite extends Component {
    constructor(props){
        super()
        this.state = {
            position: props.fixed ? "fixed" : "block"
        }
        document.getElementsByTagName("HTML")[0].setAttribute("data-theme", "white");
        
    }
    render() {
        return (
            <header style={{color:"#007BFF",position:this.state.position,width:"100%",background:"white",zIndex:"1",height:"75px"}}>
                <div className="py-3 d-flex justify-content-between container">
                    <Link className="no-decorations d-flex" to="/">
                        <img src={chart} width={30} height={30} className='img-fluid  mr-2' alt={`${process.env.REACT_APP_NAME} Icon`} />
                        <h1 className="no-decorations mt-2 nunito" style={{fontSize:"1.75em", opacity:"0.8"}}>
                            {`${process.env.REACT_APP_NAME}`}
                        </h1>
                    </Link>
                    <div className="d-none d-md-inline">
                        <Link to="/" className="mr-5 link">Inicio</Link>
                        <Link to="/signup" className="mr-5 link">Registrarse</Link>
                        <Link to="/login" className='btn btn-info px-4 py-2'>INICIAR SESIÓN</Link>
                    </div>
                    <div className="d-md-none mt-2">
                        <Link to="/signup" className="link">Registrarse</Link>
                        <Link to="/login" className='link px-4'>Entrar</Link>
                    </div>
                 
                </div>
            </header>
        )
    }
}
