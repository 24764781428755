import React, { Component } from 'react'
import Header from './Header'
import {Link} from 'react-router-dom'

export default class NotFound extends Component {
    render() {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center container" style={{height:"75vh"}}>
                    <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                    <div className="inline-block align-middle">
                        <h2 className="font-weight-normal lead" id="desc">No se encontró la página que solicitaste.</h2>
                        <h4><Link style={{textDecoration:"none"}} to="/">Ir al inicio</Link></h4>
                    </div>
                </div>
            </>
        )
    }
}
