import React, { Component, useState, useEffect } from 'react'
import Periodos from './Periodos'
import { userLoged } from '../auth';
import PeriodoService from '../services/PeriodosService'
import { connect } from 'react-redux';
import { setPeriodos } from '../actions'

const PeriodosSidebar = (props) => {
        const [styleSidebar, setStyleSidebar] = useState({ width:"250px", minWidth: "250px"})
        const styleHeader = { width:"100%"}
        const periodo_id = (props.match)?props.match.params.periodo_id: undefined;
        const { periodos } = props;
        const [collapse, setCollapse] = useState(false)
        const getPeriodos = async ()=>{
            console.log("obtieniendo datos")
            if(userLoged){
                console.log(userLoged)
                const {id} =userLoged;
                const newPeriodos =  await new PeriodoService().getPeriodos(id);
                props.setPeriodos(newPeriodos);
            }
        }
        const toggleCollapse = ()=>{
            setCollapse(!collapse)
        }

        useEffect(() => {
            getPeriodos();
        }, [])
    return (
            <>
            {
                collapse ? 
                <div className="sidebar d-flex px-0 collapse" style={{width:"3em"}}>
                    <div className="d-none d-md-block collapse-sidebar-button  text-center" onClick={toggleCollapse}>
                        <i className="fas fa-chevron-right mt-2 pt-1" title="Expandir" style={{fontSize:"1.3em"}}></i>
                    </div>
                </div>
                :
                <div className="sidebar px-0  d-flex flex-column" style={(props.context == "header") ? styleHeader : styleSidebar}>
                    <Periodos currentPeriodo={periodo_id} periodos={periodos} getPeriodos={getPeriodos}/>
                    <div className="d-none d-md-block collapse-sidebar-button mt-auto text-right" onClick={toggleCollapse} title="Contraer">
                        <hr className="my-0"/>
                        <i className="fas fa-chevron-left mt-2 pt-1 mr-2" style={{fontSize:"1.3em"}}></i>
                    </div>
                </div>
            }
        </>
    )
    
}

const mapStateToProps = state =>{
    return { periodos: state.periodos}
}

const mapDispatchToProps = { setPeriodos };

export default connect(mapStateToProps,mapDispatchToProps)(PeriodosSidebar);