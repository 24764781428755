import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar'
import {
    Menu,
    MenuItem,
    MenuDivider
} from '@szhsin/react-menu';
import { userLoged } from '../auth';
import AvatarUser from './AvatarUser';

export default class AvatarMenu extends Component {
    constructor(props){
        super();
        this.state = { theme : localStorage.getItem('theme') }
    }
    render() {
        return (
            <Menu menuButton={<Avatar style={{width:"35px", height:"35px", fontSize:"16px", cursor:"pointer"}} className="bg-success"><AvatarUser></AvatarUser></Avatar>}>
                <MenuItem>
                    <Avatar className="bg-success"><AvatarUser/></Avatar>
                    <div style={{marginLeft:"10px"}}>
                        <span>{ userLoged.name} { userLoged.lastname}</span><br/>
                        <small className="text-secondary">{ userLoged.email}</small>
                    </div><br/>
                </MenuItem>
                <MenuDivider />
                <MenuItem  href='/' styles={{fontSize:"12pt"}}>
                <i className="fas fa-home mr-2 text-secondary"></i>Inicio
                </MenuItem>
                <MenuItem href='/settings'  styles={{fontSize:"12pt"}}>
                    <i className="fas fa-cog mr-2 text-secondary"></i>Ajustes
                </MenuItem>
                {/* <MenuItem href='/cuentas' >
                    <i className="fas fa-wallet mr-2 text-secondary" ></i> Cuentas
                </MenuItem> */}
                {/* <MenuItem  href='/estadisticas' styles={{fontSize:"12pt"}}>
                <i className="fas fa-chart-line mr-2 text-secondary"></i>Estadisticas
                </MenuItem> */}
                <MenuItem  styles={{fontSize:"12pt"}} onClick={this.toggleTheme}>
                    <i className="fas fa-adjust mr-2 text-secondary" ></i>  {this.state.theme==="dark"? "Desactivar": "Activar"} Modo oscuro
                </MenuItem>
                <MenuDivider />
                <MenuItem  styles={{fontSize:"12pt"}}  onClick={this.logout}>
                <i className="fas fa-sign-out-alt mr-2 text-secondary"></i>Cerrar sesión
                </MenuItem>
            </Menu>
        )
    }

    logout = ()=>{
        localStorage.removeItem('user');
        window.location.href = '/'
    }

    toggleTheme = ()=>{
        const newMode = (localStorage.getItem('theme') == "dark")? "ligth" : "dark"
        this.setState({theme: newMode });
        localStorage.setItem('theme',newMode);
        document.getElementsByTagName("HTML")[0].setAttribute("data-theme", newMode);
    }
    
}
