class CuentasService{
    constructor(){
        this.URI = `${process.env.REACT_APP_API_URL}/api/cuentas`;
        this.TOKEN = localStorage.getItem('token');
    }

    async getCuentas(user_id){
        const response = await fetch(`${this.URI}/user/${user_id}`,{headers:{'authorization':this.TOKEN}})
        const periodos = await response.json();
        return periodos;
    }

    async getMovimientos(cuenta_id, periodo_id){
        const response = await fetch(`${this.URI}/${cuenta_id}/periodo/${periodo_id}/movimientos`,{headers:{'authorization':this.TOKEN}})
        const movimientos = await response.json();
        return movimientos;
    }


    async storeMovimiento(cuenta_id, periodo_id,movimiento){
        const response = await fetch(`${this.URI}/${cuenta_id}/periodo/${periodo_id}/store-movimiento`,{
            method:"POST",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify(movimiento)
        })
        return await response.json();
    }
    
    async addCuenta(user_id){
        const response = await fetch(`${this.URI}/`,{
            method:"POST",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({user_id:user_id})
        })
        return await response.json();
    }

    async editMovimiento(cuenta_id, periodo_id,movimiento_id,newMovimiento){
        const response = await fetch(`${this.URI}/${cuenta_id}/periodo/${periodo_id}/update-movimiento/${movimiento_id}`,{
            method:"PUT",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify(newMovimiento)
        })
        return await response.json();
    }

    async deleteMovimiento(cuenta_id, movimiento_id){
        const response = await fetch(`${this.URI}/${cuenta_id}/delete-movimiento`,{
            method:"DELETE",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({movimiento_id : movimiento_id})
        })
        return await response.json();
    }
    
    async deleteCuenta(cuenta_id,user_id){
        const response = await fetch(`${this.URI}/${cuenta_id}/`,{
            method:"DELETE",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({user_id:user_id})
        })
        return await response.json();
    }

    async setNombre(cuenta_id, newNombre){
        const response = await fetch(`${this.URI}/${cuenta_id}/update`,{
            method:"PUT",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({"nombre":newNombre})
        })
        return await response.json();
    }
}
export default CuentasService;