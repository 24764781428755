import React, { Component } from 'react'
import { NavLink} from 'react-router-dom'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default class Periodo extends Component {
    state={
        anchorEl : ''
    }
    render() {
        const {periodo} = this.props;
        return (
                <NavLink title={periodo.titulo} className="periodo d-flex px-3 py-2" to={`/app/periodo/${periodo.id}`}>
                    <span>{periodo.titulo}</span>
                    <i className="fas fa-ellipsis-h dot-options ml-auto" aria-controls="simple-menu" aria-haspopup="true"  onClick={this.handleClick}></i>
                    <Menu id="simple-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
                        <MenuItem onClick={this.deletePeriodo} style={{fontSize:"13px"}}>
                            {/* <Icon className="fa fa-trash text-danger mr-1" style={{fontSize:"13px"}}/> Eliminar */}
                            <i className="fas fa-trash text-danger mr-1"></i> <span style={{fontSize:"13px"}}>Eliminar</span>
                        </MenuItem>
                    </Menu>
                </NavLink>
        )
    }
    handleClick = (event) => {
        event.preventDefault();
        this.setState({anchorEl:event.currentTarget});
    };

    handleClose = (e) => {
        this.setState({anchorEl:null});
        if(e) e.preventDefault();
    };

    deletePeriodo = (e)=>{
        if(e) e.preventDefault();
        this.props.deletePeriodo(this.props.periodo.id);
    }
}