class LandingService {

    constructor(){
        this.URI = `${process.env.REACT_APP_API_URL}/api`;
    }

    async sendContact(messsage){
        const response = await fetch(`${this.URI}/contact`,{
            method:"POST",
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify(messsage)
        })
        return await response.json();
    }

    async newsletterRegistry(email){
        const response = await fetch(`${this.URI}/newsletter`,{
            method:"POST",
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify({email})
        })
        return await response.json();
    }
}


export default LandingService;