import React from 'react'
import chart from '../img/Chart2.svg'

export default function AuthBranding() {
    return (
        <div className="">
            <div className='d-flex'>
                <img src={chart} width={30} height={30} className='img-fluid  mr-2' alt={`${process.env.REACT_APP_NAME} Icon`} />
                <h1 className='nunito mt-2'>{`${process.env.REACT_APP_NAME}`}</h1>
            </div>
            <br />
            <p>La mejor herramienta para mantener el control de tus gastos <br></br>e ingresos en un solo sitio y de la manera mas sencilla</p>
        </div>
    )
}
