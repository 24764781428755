import React, { useState } from 'react'
import { connect } from 'react-redux';
import { setAvatar } from '../actions'
import AvatarUser from './AvatarUser';
import BtnDeleteImg from './BtnDeleteImg';
import Avatar from '@material-ui/core/Avatar'
import UserService from '../services/UserService';


const SettingsPerfilAvatar = (props)=> {
    const {user, onShowSnack} = props;
    const [saving, setSaving]  = useState(false);
    const [errors, setErrors]  = useState();

    const handleButtonUpdateImg = (e)=>{
        document.getElementById('fileInput').click();
    }

    const onChangeAvatar = async (e)=>{
        if(e.target.files.length>0){
            const img = e.target.files[0];
            setSaving(true);
            const resp = await new UserService().updateImg(img);
            setSaving(false);
            if(resp.status){
                let user = JSON.parse(localStorage.getItem('user'));
                user.img = resp.imgUrl;
                localStorage.setItem('user',JSON.stringify(user));
                props.setAvatar(resp.imgUrl)
                if(onShowSnack){
                    onShowSnack(resp.msg)
                }
            }else{
                if(resp.errors){
                    setErrors(resp.errors);
                }
            }
        }
    }
    
    return (
        <div className="card">
            <div className="card-header">
                <strong className="card-title">Foto de perfil</strong>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <Avatar style={{ width:"96px", height:"96px",fontSize:"2.5em" }} className="bg-success mx-auto">
                            <AvatarUser/>
                        </Avatar>
                    </div>
                    <div className="col-md-9 pt-3">
                        <button className="btn btn-sm btn-primary" type="button" onClick={handleButtonUpdateImg}> 
                            { saving && <div className="spinner-border spinner-border-sm mr-2" role="status"><span className="sr-only">Loading...</span></div> }
                            Actualizar imagen
                        </button>
                        <small className="d-block mt-2">Debe ser un archivo JPEG, PNG o GIF de máximo 10 MB.</small>
                        <input style={{display:"none"}} type="file"  accept=".png, .jpg, .jpeg, .gif" id="fileInput" onChange= {onChangeAvatar}/>
                        { (user.img && user.img!= "") &&  <BtnDeleteImg onShowSnack={onShowSnack}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state =>{
    return {
        user: state.user
    }
}

const mapDispatchToProps = { setAvatar }

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPerfilAvatar)