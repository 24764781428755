import React, { Component } from 'react'
import { GoogleLogin } from 'react-google-login'
import UserService from '../services/UserService';

export default class GoogleLoginBtn extends Component {
    render() {
        return (
            <div>
                <GoogleLogin
                    
                    clientId="942251851725-hpujffkht4esf85jevs87rnh2hqdi70v.apps.googleusercontent.com"//clientId="342690359519-iv6l2siflschqrfu9gli68i41j25knpa.apps.googleusercontent.com"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-danger w-100"><i className="fab fa-google"></i> Google</button>
                    )}
                    cookiePolicy={'single_host_origin'}
                />
            </div>
        )
    }


    responseGoogle = async (response) => {
        console.log(response);
        const { tokenId, profileObj } = response;
        if(profileObj){
            const user = {
                email: profileObj.email,
                name: profileObj.givenName,
                lastname: profileObj.familyName,
                img: profileObj.imageUrl
            }
            const resp = await new UserService().oAuthLogin(user, tokenId);
            console.log(resp)
            if(resp.status){
                localStorage.setItem('user',JSON.stringify(resp.user));
                localStorage.setItem('token',resp.token);
                window.location.href = '/'
            }

        }
    }

}
