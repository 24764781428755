import React, { useState } from 'react'
import { connect } from 'react-redux';
import { setUserInfo } from '../actions'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';
import BtnDeleteUser from './BtnDeleteUser';
import UserService from '../services/UserService';

import SettingsPerfilAvatar from './SettingsPerfilAvatar';

const SettingsPerfil = (props) => {
    const { user } = props;
    const [userForm, setValues]  = useState(user);
    const [saving, setSaving]  = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors(undefined)
        setSaving(true);
        const resp = await new UserService().update(userForm);
        setSaving(false);
        if(resp.status){
            props.setUserInfo(userForm)
            localStorage.setItem('user',JSON.stringify(userForm));                
            setSnackBarOpen(true);
        }else{
            if(resp.errors){
                setErrors(resp.errors);
            }
        }
    }

    const handleChangeUser = (e)=>{
        setValues({
            ...userForm, 
            [e.target.name] : e.target.value
        })
    }

    const handleCloseSnack = (e,reason)=>{ setSnackBarOpen(false); }

    const showSnackMessage = (msg)=>{ 
        setSnackMsg(msg);
        setSnackBarOpen(true);
    }

    return (
        <form action="" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12 col-md-4">
                    <h4>Cuenta</h4>
                    <p className="text-secondary">Esta es la información de su perfil.</p>
                </div>
                <div className="col-12 col-md-7">
                    <SettingsPerfilAvatar onShowSnack={showSnackMessage}/>
                    <div className="card mt-3">
                        <div className="card-header">
                            <strong className="card-title">Información personal</strong>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="name">Nombre</label>
                                <input type="text" className="form-control" name='name' value={userForm.name} onChange={handleChangeUser} required/>
                                {  errors && errors.name && <small className='text-danger'>{ errors.name}</small>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Apellido</label>
                                <input type="text" className="form-control" name='lastname' value={userForm.lastname} onChange={handleChangeUser} required/>
                                {  errors && errors.lastname && <small className='text-danger'>{ errors.lastname}</small>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Correo Electronico</label>
                                <input type="email" className="form-control" name='email' value={userForm.email} onChange={handleChangeUser} required/>
                                {  errors && errors.email && <small className='text-danger'>{ errors.email}</small>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row"><div className="col-12 col-md-11"><hr/></div></div>
            <div className="row">
                <div className="col-12 col-md-11 d-flex justify-content-between">
                    <BtnDeleteUser/>
                    <button type="submit" className="btn btn-success">
                        { saving ? <div className="spinner-border spinner-border-sm" role="status"><span className="sr-only">Loading...</span></div> : "Guardar" }
                    </button>
                </div>
            </div>

            <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleCloseSnack}>
                <MuiAlert elevation={6} variant="filled"  severity="success">
                    Cambios Guardados!
                </MuiAlert>
            </Snackbar>
        </form>
    )
}

const mapStateToProps = state =>{
    return {
        user: state.user
    }
}

const mapDispatchToProps = { setUserInfo }

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPerfil)
