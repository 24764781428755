import 'rsuite/dist/styles/rsuite-default.css'
import '@szhsin/react-menu/dist/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';
import './App.css';
import { userLoged } from "./auth";
import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import analytics, {logEvent} from './firebase'

// Componentes
import Home from './components/Home'
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import NotFound from './components/NotFound';
import ProtectedRoute from './components/ProtectedRoute'
import Settings from './components/Settings'
import Landing from './landing/Landing';
import EstadisticasPage from './components/EstadisticasPage';
import {setUserProperties} from 'firebase/analytics';


function App() {
  let mode = localStorage.getItem('theme');
  if(mode===undefined){
    localStorage.setItem('theme',"ligth");
  }
  document.getElementsByTagName("HTML")[0].setAttribute("data-theme", localStorage.getItem('theme'));

  setUserProperties(analytics,{email: userLoged?.email});

  return (
    <Router>
      <Switch>
          <Route exact path="/">{ userLoged ? <Redirect to='/app'/> :  <Landing/>}</Route>
          <Route exact path="/login"> { userLoged ? <Redirect to='/app'/> :  <LoginPage/>}</Route>
          <Route exact path="/signup">{ userLoged ? <Redirect to='/app'/> :  <SignupPage/>}</Route>
          <ProtectedRoute exact path="/app/periodo/:periodo_id" component={Home}/>
          <ProtectedRoute exact path="/app" component={Home}/>
          <ProtectedRoute path="/settings/:ajuste" component={Settings}/>
          <ProtectedRoute path="/settings/" component={Settings}/>
          {/* <ProtectedRoute path="/estadisticas/" component={EstadisticasPage}/> */}
          {/* <ProtectedRoute path="/cuentas/" component={Cuentas}/> */}
          <Route component={NotFound}/>
      </Switch>
    </Router>
  );
}
export default App;