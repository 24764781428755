import React, { useState }from 'react'
import LandingService from '../services/LandingService'
import 'animate.css'


export default function ContactForm() {
    const [name,    setName]  = useState('');
    const [email,   setEmail]   = useState('');
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [messageSended, setMessageSended] = useState(false);


    const onSubmit = async (e)=>{
        e.preventDefault();
        setMessageSended(false);
        setSending(true)
        const resp = await new LandingService().sendContact({ name, email, message});
        setSending(false)
        setMessageSended(true);
        setTimeout(() => {
            setMessageSended(false);
            
        }, 6000);
    }

    return (
        <form onSubmit={onSubmit} className="container">
            <div className="row mx-0  animate__animated animate__fadeInUp">
                <div className="col-12">
                    <h2 className="text-left mb-4">Contáctanos</h2>
                    <p>Queremos escucharte. Enviamos un mensaje.</p>
                </div>
            </div>
            { 
                messageSended && 
                <div className="row mx-0">
                    <div className="col-md-12">
                        <div class="alert alert-success"  role="alert">Mensaje enviado correctamente! Gracias, responderemos en breve.</div>
                    </div>
                </div>
            }
            <div className="row mx-0 mb-3">
                <div className="col-12 col-md-6 mb-2 animate__animated animate__fadeInLeft">
                    <label htmlFor="name" style={{fontSize:14}}>Nombre *</label>
                    <input type="text" className="form-control" placeholder="Nombre" name="name" id="name" onChange={(e)=>{ setName(e.target.value); }} required/>
                </div>
                <div className="col-12 col-md-6 mb-2 animate__animated animate__fadeInRight">
                    <label htmlFor="email" style={{fontSize:14}}>Correo Electrónico *</label>
                    <input type="email" className="form-control" placeholder="Correo electrónico" name="email" id="email" required onChange={(e)=>{ setEmail(e.target.value); }}/>
                </div>
            </div>
            <div className="row mx-0 mb-3 animate__animated animate__fadeInUp">
                <div className="col-12">
                    <label htmlFor="mensaje" style={{fontSize:14}}>Mensaje *</label>
                    <textarea name="" id="" placeholder="Mensaje" cols="30" rows="10" id="mensaje" className="form-control" onChange={(e)=>{ setMessage(e.target.value); }} required></textarea>
                    <p className="text-secondary mt-3">* Estos campos son requeridos</p>
                </div>
            </div>
            <div className="row mx-0 animate__animated animate__fadeInUp">
                <div className="col-12">
                    <button className="btn btn-primary">
                        { sending && <div className="spinner-border spinner-border-sm mr-2" role="status"><span className="sr-only">Loading...</span></div> }
                        Enviar mensaje
                    </button>
                </div>
            </div>
        </form>
    )
}
