import React, { Component, useState } from 'react'
import { connect } from 'react-redux';
import { deleteCuenta } from '../actions'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CuentasService from '../services/CuentasService';
import { userLoged } from '../auth';
import InputGroupButton from 'rsuite/lib/InputGroup/InputGroupButton';

const LinkDeleteCuenta = (props)=> {
        const { cuenta_id, cuenta_nombre } = props;
        const [openConfirm, setOpenConfirm] = useState(false)

        const showConfirm = (e)=>{
            e.preventDefault();
            setOpenConfirm(true)
        }
    
        const handleCancel = (e)=>{
            if(props.onClick) props.onClick();
            setOpenConfirm(false);
        }

        const deleteCuenta = async (e)=>{
            const resp = await new CuentasService().deleteCuenta(cuenta_id,userLoged.id)
            if(resp){
                // if(props.getCuentas) props.getCuentas();
                props.deleteCuenta(cuenta_id)
                
                setOpenConfirm(false)
            }else{
                console.log(resp)
            }
        }

        return (
            <>
                <small className="text-danger link-delete-cuenta" onClick={showConfirm}>Eliminar cuenta </small>
                <Dialog
                    aria-labelledby="confirmation-dialog-title"
                    open={openConfirm}
                    onClose={handleCancel}
                    >
                    <DialogTitle id="confirmation-dialog-title" className="text-center">
                        ¿Confirmas que quieres eliminar <b>{cuenta_nombre}</b>?
                    </DialogTitle>
                    <DialogContent dividers className="text-center">
                        Este cambio aplica para todas todos los periodos
                    </DialogContent>
                    <DialogActions>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancelar</button>
                        <button type="button" className="btn btn-danger"    onClick={deleteCuenta}>Eliminar</button>
                    </DialogActions>
                </Dialog>
            </>
        )
    
    
}

const mapDispatchToProps = { deleteCuenta };
export default connect(null,mapDispatchToProps)(LinkDeleteCuenta);