import { userLoged } from "../auth";

class UserService {

    constructor(){
        this.URI = `${process.env.REACT_APP_API_URL}/api/user`;
        this.TOKEN = localStorage.getItem('token');
    }

    async signup(user){
        const response = await fetch(`${this.URI}/signup`,{
            method:"POST",
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify(user)
        })
        return await response.json();
    }
    async login(user){
        const response = await fetch(`${this.URI}/login`,{
            method:"POST",
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify(user)
        })
        return await response.json();
    }

    async oAuthLogin(user){
        const response = await fetch(`${this.URI}/oAuth`,{
            method:"POST",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify(user)
        })
        return await response.json();
    }

    async credentialsType(){
        const { id,email } = userLoged;
        const response = await fetch(`${this.URI}/credentialsType`,{
            method:"POST",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({id,email})
        })
        return await response.json();
    }

    async update(user){
        const response = await fetch(`${this.URI}/update`,{
            method:"PUT",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify(user)
        })
        return await response.json();
    }

    async updatePassword(currentPassword, password, confirmPassword){
        const { id } = userLoged;
        const response = await fetch(`${this.URI}/updatePassword`,{
            method:"PUT",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({currentPassword, password, confirmPassword, id})
        })
        return await response.json();
    }

    async updateImg(imgFile){
        const { id } = userLoged;
        const formData = new FormData();
        formData.append('id',id);
        formData.append('img',imgFile);
        const response = await fetch(`${this.URI}/updateImg`,{
            method:"PUT",
            headers: { 'authorization':this.TOKEN},
            body: formData
        })
        return await response.json();
    }

    async deleteAccount(){
        const response = await fetch(`${this.URI}`,{
            method:"DELETE",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({user_id: userLoged.id})
        })
        return await response.json();
    }

    async deleteImage(){
        const response = await fetch(`${this.URI}/delete/img`,{
            method:"DELETE",
            headers: { 'Content-Type':'application/json','authorization':this.TOKEN},
            body: JSON.stringify({user_id: userLoged.id})
        })
        return await response.json();
    }

}

export default UserService;