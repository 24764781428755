export const setUserInfo = payload => ({
    type: 'SET_USER_INFO',
    payload, 
});

export const setAvatar = payload => ({
    type: 'SET_AVATAR',
    payload, 
});

export const setPeriodos = payload => ({
    type: 'SET_PERIODOS',
    payload, 
});

export const changePeriodoTitle = payload => ({
    type: 'CHANGE_PERIODO_TITLE',
    payload, 
});

export const setCuentas = payload => ({
    type: 'SET_CUENTAS',
    payload, 
});

export const updateCuentaNombre = payload => ({
    type: 'UPDATE_CUENTA_NOMBRE',
    payload, 
});

export const deleteCuenta = payload => ({
    type: 'DELETE_CUENTA',
    payload, 
});