import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { userLoged } from './auth';
import reducer from './reducers'

import App from './App';

const initialState = {
    user: userLoged,
    periodos: [],
    cuentas: []
}

const store = createStore(reducer, initialState);

ReactDOM.render( 
    <Provider store={store}>
        <App/>
    </Provider>
,document.getElementById('root'));
