import React, { Component } from 'react'
import HeaderSite from '../components/HeaderSite'
import '../css/landing.css'
import ContactForm from './ContactForm'
import 'animate.css'
import { Link } from 'react-router-dom'
import screenExample from '../img/screen-example.png'
import Footer from './Footer'
import Newsletter from './Newsletter'
export default class Landing extends Component {
    constructor(){
        super()
        this.state={
            email:''
        }
    }
    render() {
        return (
           <div style={{color:"white",width:"100%"}}>
               <HeaderSite/>
                <main
                // style={{paddingTop:"75px"}}
                >
                    <section className="full-heigth blue-gradient">
                        <div className="row mx-0" style={{width:"95%"}}>
                            <div className="offset-md-2 col-md-4 animate__animated animate__slideInLeft">
                                <h2 className="mb-4 pb-2">Manten el control de tus gastos e ingresos de la manera mas <b>fácil</b></h2>
                                <h3 className="mb-4 pb-4" style={{fontSize:"1.3em"}}>Todas tus cuentas en un solo lugar</h3>
                                <div className="mb-4 wraper-star-now" >
                                    <Link to="/signup" className="btn btn-lg btn-info btn-shadow">¡Empezar ya!</Link>
                                </div>
                            </div>
                            <div className="col-md-6 p-0 animate__animated animate__zoomIn text-center">
                                <img className='img_template_browser' src="/img_landing.svg" alt="img_template_browser"/>
                            </div>
                        </div>
                    </section>
                    <section className="mid-heigth text-dark gradient-2">
                        <div className="row mx-0 w-100">
                            <div className="offset-md-2 col-md-5">
                                <img className='img_template_movimientos animate__animated animate__zoomIn' src="/movimientos_landing.svg" alt="img_template_movimientos"/>
                            </div>
                            <div className="col-md-4 animate__animated animate__slideInRight">
                                <h2 className="my-4 py-2">Registra tus <b>movimientos</b></h2>
                                <h3 className="mb-4 pb-4" style={{fontSize:"1.3rem",}}>La pizza de anoche, el pedido en mercado libre <br></br>o amazon, todo!</h3>
                            </div>
                        </div>
                    </section>
                    <section className="full-heigth blue-gradient">
                        <div className="row mx-0">
                            <div className="offset-md-1 col-md-5 animate__animated animate__zoomIn">
                                <h2>Comienza ahora a tomar el <b>control</b> de tus finanzas</h2>
                                <form className="mt-5 row" onSubmit={this.onSubmitEmail}>
                                    <div className="col-12 col-md-6 mb-3">
                                        <input type="email" name="email" placeholder="Correo electrónico" className="form-control mr-3" value={this.state.email} onChange={this.onChangeEmail}/>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <button type="submit" className="btn btn-info">Registrarme</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6 animate__animated animate__zoomIn">
                                <img src={screenExample} className="img-example img-fluid" alt="screenExample"/>
                            </div>
                        </div>                        
                    </section>

                    <section className="mid-heigth d-block text-dark ">
                        <ContactForm></ContactForm>
                    </section>

                    <Newsletter/>
                    <Footer/>
                </main>
            </div>
        )
    }

    onChangeEmail = (e)=>{
        this.setState({email:e.target.value});
    }

    onSubmitEmail = async (e)=>{
        e.preventDefault();
        await localStorage.setItem('signup_email',this.state.email);
        window.location.href= `/signup`;
    }
}
