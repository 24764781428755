import React, { Component } from 'react'

export default class StartPageMain extends Component {
    render() {
        return (
            <div className="main">
                    <i className="fas fa-share d-inline-block d-md-none" style={{fontSize:"2em",transform:"rotate(-90deg)",color:"var(--periodo-text-color)"}}></i>
                    <h4 className="mt-md-5 text-center">Para iniciar selecione un grupo <br/>o cree uno nuevo.
                        <i className="fas fa-share d-none d-md-block mt-5" style={{fontSize:"5em",transform:"rotate(185deg)",color:"var(--periodo-text-color)"}}></i>
                    </h4>
            </div>
        )
    }
}
