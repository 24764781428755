import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { setUserInfo } from '../actions'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';

import NotificationsService from '../services/NotificationsService';
import { logEvent } from '../firebase';

const SettingsNotificaciones = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [notificationsUser, setNotificationsUser] = useState([]);
    
    useEffect(() => {
        (new NotificationsService()).getAll()
            .then(response => {
                setNotifications(response);
            })
        if (props.user.id) {
            getNotificationsUser();
        }
    }, [props])


    const getNotificationsUser = () => {
        (new NotificationsService()).getByUser()
        .then(response => {
            setNotificationsUser(response);
        })
    }

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const handleSubmit = () => { }

    const handleCloseSnack = (e, reason) => { setSnackBarOpen(false); }
    
    const updateNotification = (notificationId,slug) =>{
        let notification = notificationsUser.find(notificationUser => notificationUser.notificacion_id === notificationId);
        if(notification){
            (new NotificationsService()).deleteNotificationUser(notificationId)
            .then(response => {
                getNotificationsUser();
                setSnackBarOpen(true);
                logEvent(`notification_${slug}_subscribe`);
            })
        }else{
            (new NotificationsService()).addNotificationUser(notificationId)
            .then(response => {
                getNotificationsUser();
                setSnackBarOpen(true);
                logEvent(`notification_${slug}_unsubscribe`);
            })
        }
     }

    return (
        <form action="" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12">
                    <h4>Notificaciones</h4>
                    <p className="text-secondary">Active o desactive notificaciones por correo electronico.</p>
                </div>
                <div className="col-12 mt-3 mb-3">
                    <div className="card ">
                        <div className="card-header">
                            <strong className="card-title">Notificaciones</strong>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                {
                                    notifications.map((notification, index) => {
                                        return <div className="form-check mb-3">
                                            <input 
                                                className="form-check-input" 
                                                checked={notificationsUser.find(notificationUser => notificationUser.notificacion_id === notification.id )} 
                                                type="checkbox" 
                                                value="" 
                                                id={`notification_${notification.id}`} 
                                                onChange={()=>updateNotification(notification.id,notification.slug)}
                                            />
                                            <label className="form-check-label" htmlFor={`notification_${notification.id}`}>
                                                {notification.description}
                                            </label>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleCloseSnack}>
                <MuiAlert elevation={6} variant="filled" severity="success">
                    Cambios Guardados!
                </MuiAlert>
            </Snackbar>
        </form>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = { setUserInfo }

export default connect(mapStateToProps, mapDispatchToProps)(SettingsNotificaciones)
