import React, { Component } from 'react'
import UserService from '../services/UserService'
import GoogleLoginBtn from './GoogleLoginBtn'
import HeaderSite from './HeaderSite'
import PasswordInput from './PasswordInput'
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from 'react-router-dom'
import AuthBranding from './AuthBranding'
import DocumentTitle from './DocumentTitle'

export default class SignupPage extends Component {
    constructor(props){
        super();
        const signupEmail = localStorage.getItem('signup_email');
        const reRef = React.createRef();
        this.state= {
            name:'',
            lastname:'',
            email:signupEmail? signupEmail: "",
            password:'',
            confirmPassword:'',
            showPassword:false,
            showConfirmPassword:false,
            reRef: reRef,
            errors:{},
        }
        localStorage.removeItem('signup_email');
    }

   
    onChangeInput = (e)=>{
        this.setState({[e.target.name] : e.target.value})
    }
    onSubmit = async(e) =>{
        e.preventDefault();
        this.setState({errors:{}})
        
        if(this.matchPassword() && this.passwordValidity()){

            const tokenRecaptcha = await this.state.reRef.current.executeAsync();
            this.state.reRef.current.reset();

            const user = {
                name: this.state.name,
                lastname: this.state.lastname,
                email: this.state.email,
                password: this.state.password,
                tokenRecaptcha
            }
            const resp = await new UserService().signup(user);
            if(resp.status){

                const resp = await new UserService().login(user);
                if(resp.status){
                    localStorage.setItem('user',JSON.stringify(resp.user));
                    localStorage.setItem('token',resp.token);
                }
                window.location.href = '/'
            }else{
                if(resp.errors){
                    this.setState({errors:resp.errors});
                }
            }
        }
    }
    render() {
        return (
            <>
                <DocumentTitle title="Registro"/>
                <HeaderSite/>
                <div className="row mx-0" style={{height:"calc(100vh - 75px)"}}>
                    <div className="col-md-6 align-self-center">
                        <div className="container mx-auto mt-5 pt-md-5 pt-0 px-md-5">
                        <div className=' d-flex d-md-none mt-3' >
                            <AuthBranding/>
                        </div>

                            <form className="mt-4 pb-3" onSubmit={this.onSubmit}>
                            <div className="row mb-3">
                                    <div className="col-md-11 offset-md-1">
                                        <h1 style={{fontSize:"16pt",}} className="mb-4">Ingresa tus datos para crear tu cuenta</h1>
                                    </div>
                                    <div className="col-12 offset-md-1 col-md-5 offset-md-1 mb-3">

                                        <label htmlFor="name">Nombre</label>
                                        <input type="text" name='name' id="name" value={this.state.name} onChange={this.onChangeInput} className="form-control" required/>
                                        { this.state.errors && this.state.errors['name'] &&<small className="text-danger">Error</small>}
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <label htmlFor="lastname">Apellido</label>
                                        <input type="text" name='lastname' id="lastname" value={this.state.lastname} onChange={this.onChangeInput} className="form-control" required/>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 offset-md-1 col-md-10">
                                        <label htmlFor="email">Correo electrónico</label>
                                        <input type="email" name='email' id="email" value={this.state.email} onChange={this.onChangeInput} className="form-control" required/>
                                        { this.state.errors && this.state.errors.email && (<small style={{display:"block"}} className="text-danger">{this.state.errors.email}</small>)}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 offset-md-1 col-md-10">
                                        <label htmlFor="password">Contraseña</label>
                                        <PasswordInput onChange={this.onChangeInput} name='password' id='password' required={true}></PasswordInput>
                                        {/* <div className="input-group">
                                            <input type={this.state.showPassword ? "text": "password"} name='password' id="password" value={this.state.password} onChange={this.onChangeInput} className="form-control" required/>
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-primary" onClick={()=> this.setState({showPassword: !this.state.showPassword}) }><i className={this.state.showPassword ? "fa fa-eye":"fa fa-eye-slash"} aria-hidden="true"></i></button>
                                            </div>
                                        </div> */}
                                        <small>Al menos 5 caracteres. Te recomendamos usar una combinación de números, mayusculas y minusculas.</small>
                                        { (!this.passwordValidity() && this.state.password !== '') && this.state.errors && this.state.errors.password && (<small style={{display:"block"}} className="text-danger">{this.state.errors.password}</small>)}
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12 offset-md-1 col-md-10">
                                        <label htmlFor="password">Confirma tu contraseña</label>
                                        <PasswordInput onChange={this.onChangeInput} name='confirmPassword' id='confirmPassword' required={true}></PasswordInput>
                                        {/* <div className="input-group">
                                            <input type={this.state.showConfirmPassword ? "text": "password"} name='confirmPassword' id="confirmPassword" value={this.state.confirmPassword} onChange={this.onChangeInput} className="form-control" required/>
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-primary" onClick={()=> this.setState({showConfirmPassword: !this.state.showConfirmPassword}) }><i className={this.state.showConfirmPassword ? "fa fa-eye":"fa fa-eye-slash"} aria-hidden="true"></i></button>
                                            </div>
                                        </div> */}
                                        { (this.state.confirmPassword !== '')? <p style={{fontSize:"10pt", color: this.matchPassword()? "green":"red"}}>{ 
                                         (this.state.password === this.state.confirmPassword)? 'Las contraseñas coinciden' : 'Las contraseñas no coinciden'
                                        }</p>:<></>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4 offset-md-3">
                                        <button type="submit" className="btn btn-primary w-100">Registrarme</button>
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            size="invisible"
                                            ref={this.state.reRef}
                                        ></ReCAPTCHA>
                                    </div>
                                </div>
                            </form>



                            <div className="row">
                                <div className="col-12 col-md-6 offset-md-2">
                            <p className="text-center mb-3">¿Ya estas registrado? <Link to="/login">Inicia sesión</Link></p>
                            <p className="text-center mb-3">Ó conectate con...</p>
                                    <GoogleLoginBtn/>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className='col-md-6 d-none d-md-flex align-items-center container' style={{ minHeight: "70vh" }} >
                        <AuthBranding/>
                    </div>
                </div>
                
            </>
        )
    }

    passwordValidity = ()=>{
        if(this.state.password.length < 5){
            this.state.errors = {password: "La contraseña debe tener al menos 5 caracteres"}
            return false;
        }else{
            return true;
        }
    }
    matchPassword = ()=>{
        if(this.state.password === this.state.confirmPassword){
            return true;
        }else{
            return false;
        }
    }
}
