import React, { Component } from 'react'
import Periodo from './Periodo'
import PeriodoForm from './PeriodoForm'
import {now} from '../utils'
import PeriodoService from '../services/PeriodosService'
import { userLoged } from '../auth'

export default class Periodos extends Component {
    render() {
        return (
            <>
                <PeriodoForm addPeriodo={this.addPeriodo}/>
                <hr className="mb-0"/>
                <div id="periodo-wraper">
                    { 
                    this.props.periodos && this.props.periodos.map(periodo => {
                        return <Periodo deletePeriodo={this.deletePeriodo} periodo={periodo} key={periodo.id}></Periodo>
                        })
                    }
                </div>

                
            </>
        )
    }

    addPeriodo = async (titulo)=>{
        const newPeriodo = { titulo : titulo, created_at:now(), movimientos: []};
        await new PeriodoService().save(newPeriodo, userLoged.id)
        this.props.getPeriodos();
    }

    deletePeriodo = async ( periodo_id ) => {
        const resp = await new PeriodoService().delete(periodo_id)
        if(resp.status){
            if(periodo_id === parseInt(this.props.currentPeriodo)){
                window.location.href = '/';
            }else{
                this.props.getPeriodos();
            }
        }
    }
}
