import React, { Component } from 'react'
import { connect } from 'react-redux';
// import { userLoged } from '../auth'
import Avatar from '@material-ui/core/Avatar'

class AvatarUser extends Component {
    constructor(props){
        super();
    }
    render() {
        return (
            <>
                {
                    (this.props.user.img && this.props.user.img!="")? 
                        <img src={ (this.props.img)?  `${process.env.REACT_APP_API_URL}/uploads/${this.props.img}`: ((this.props.user.img && this.props.user.img.includes("http"))?  this.props.user.img : `${process.env.REACT_APP_API_URL}/uploads/${this.props.user.img}`)} style={{  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} className="img-fluid" alt="img"/>
                        : (this.props.user.name && this.props.user.lastname) ? (this.props.user.name[0].toUpperCase()+this.props.user.lastname[0].toUpperCase()) : ""
                }
            </>
        )
    }
}

const mapStateToProps = state =>{
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(AvatarUser)