import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import UserService from '../services/UserService';
import Swal from 'sweetalert2'

export default class BtnDeleteUser extends Component {
    constructor(props){
        super();
        this.state = {
            openConfirm: false,
        }
    }
    render() {
        return (
            <>
                <button type="button" className="btn btn-sm btn-outline-danger" onClick={this.showConfirm}>Eliminar mi cuenta</button>
                <Dialog
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.openConfirm}
                    onClose={this.handleCancel}
                    >
                    <DialogTitle id="confirmation-dialog-title" className="text-center">
                        ¿Confirmas que quieres eliminar tu cuenta?
                    </DialogTitle>
                    <DialogContent dividers className="text-center">
                        <b>Al confirmar se eliminara toda tu información</b>
                    </DialogContent>
                    <DialogActions>
                        <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>Cancelar</button>
                        <button type="button" className="btn btn-danger"    onClick={this.deleteAccount}>Eliminar</button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    
    showConfirm = (e)=>{
        e.preventDefault();
        this.setState({...this.state, openConfirm:true})
    }

    handleCancel = (e)=>{
        if(this.props.onClick) this.props.onClick();
        this.setState({...this.state, openConfirm:false})
    }
    deleteAccount = async ()=>{
        this.setState({...this.state, openConfirm:false})
        const resp = await new UserService().deleteAccount();
        let timerInterval
        await Swal.fire({
        title: 'Hasta luego!',
        html: 'Eliminando información',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
            const content = Swal.getContent()
            if (content) {
                const b = content.querySelector('b')
                if (b) {
                b.textContent = Swal.getTimerLeft()
                }
            }
            }, 100)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }
        })
        
        if(resp){
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            window.location.href= '/';
        }
    }
}
