import React, { useState, useEffect } from 'react'
import PeriodosSidebar from './PeriodosSidebar';
import Main from './Main';
import StartPageMain from './StartPageMain';
import Header from './Header';
import { connect } from 'react-redux';

const Home = (props)=>{
    console.log(props)
    const periodo_id = (props.match)? props.match.params.periodo_id: undefined;
    
    const { periodos } = props;
    
    const periodoFilter = ()=>{
        if(periodos){
            const periodosMatch = periodos.filter((periodo)=> {return periodo.id == periodo_id});
            if(periodosMatch.length >0){
                return periodosMatch[0];
            }
        }
    }
    const periodo = periodoFilter();
    return (
        <>
        <Header/>
            <div style={{width:"100%",  display: "flex", alignItems: "stretch"}}>
                <div className="d-none d-md-block">
                    <PeriodosSidebar context="sidebar"/>
                </div>
                { (periodo_id)? <Main periodo={periodo}/>:<StartPageMain/> }
            </div>
    </>
    );
}


const mapStateToProps = state =>{
    return { periodos: state.periodos}
}

export default connect(mapStateToProps, null)(Home);