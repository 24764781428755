import React, { useState, useEffect } from 'react'
import {setCuentas} from '../actions'
import PeriodoService from '../services/PeriodosService'
import CuentaService from '../services/CuentasService'
import Cuenta from './Cuenta'
import { Helmet } from 'react-helmet'
import { userLoged } from '../auth'
import { formatMoney } from '../utils'
import TitlePeriodoForm from './TitlePeriodoForm'
import { connect } from 'react-redux'
import { logEvent } from '../firebase'

const Main = (props)=> {
    const {periodo, cuentas, setCuentas} = props;
    const [total, setTotal] = useState(0);
    const [editingTitle, setEditingTitle] = useState(false);

    useEffect(() => {
        if(periodo){ 
            getCuentas()
            document.title= `${process.env.REACT_APP_NAME} - ${periodo.titulo}`;
            logEvent('show_periodo', {
                'periodo': periodo?.titulo
            });
        }
    }, [periodo])

    const getTotal = async (id) => {
        console.log("getTotal",id)
        const resp = await new PeriodoService().getTotal(id);
        setTotal(resp.total);
    }
    
    const getCuentas = async () => {
        if(userLoged){
            getTotal(periodo.id);
            const cuentas = await new CuentaService().getCuentas(userLoged.id)
            setCuentas(cuentas);
        }
    }

    const addCuenta = async () =>{
        if(userLoged){
            const resp = await new CuentaService().addCuenta(userLoged.id)
            if(resp.status){
                logEvent('create_cuenta');
                getCuentas();
            }else{
                alert('ocurrio un error')
            }
        }
    }

    const startEditTitle =async ()=>{
        setEditingTitle(true);
        setTimeout(() => {
            const periodoTitle = document.querySelector('#form-title');
            if(periodoTitle) periodoTitle.focus();
        }, 250);
    }


    if(periodo === undefined ) {
        return <div className='main'>Sin datos</div>
    }else {
        return (
            <div className='main'>
                {
                    editingTitle ? 
                        <TitlePeriodoForm  onStopEdit={()=>{setEditingTitle(false)}} periodo={periodo}></TitlePeriodoForm>
                        : <div className="mb-2">
                            <h4 className="d-inline-block">{ periodo.titulo}</h4>
                            <i className="fas fa-pen edit-icon ml-2" onClick={startEditTitle} style={{verticalAlign:"middle"}}></i>
                            <h4 className={ (total>=0)? 'color-green float-right mr-4':'color-red float-right mr-4'}>{formatMoney(total)}</h4>
                        </div>
                }
                <Helmet>
                    <title></title>
                </Helmet>
                {/* Cuentas */}
                <div id="cuentas-wrapper">
                    <div className="d-flex justify-content-start"  >
                        { 
                            cuentas && 
                            cuentas.map((cuenta)=>{ return <Cuenta onUpdate={getTotal} getCuentas={getCuentas} key={cuenta.id} cuenta={cuenta} periodo={periodo}/>})
                        }
                        <div className="newCuenta" onClick={addCuenta}>
                            <p className="m-0"><i className="fas fa-plus"></i> Añadir nueva cuenta</p>
                        </div>
                    </div>
                </div>
            </div>);
    }
}


const mapStateToProps = (state) =>{
    return {
        cuentas: state.cuentas
    }
}

const mapDispatchToProps = { setCuentas };

export default connect(mapStateToProps, mapDispatchToProps)(Main);