import React, { Component } from 'react'
import UserService from '../services/UserService'
import GoogleLoginBtn from './GoogleLoginBtn'
import HeaderSite from './HeaderSite'
import { Link } from 'react-router-dom'
import PasswordInput from './PasswordInput'
import AuthBranding from './AuthBranding'
import DocumentTitle from './DocumentTitle'
export default class LoginPage extends Component {
    state = {
        email:'',
        password:'',
        errors:{},
        rememberMe: false
    }

    onChangeInput = (e)=>{
        this.setState({[e.target.name] : e.target.value})
    }

    onChangeCheck = (e)=>{
        this.setState({[e.target.name] : e.target.checked})
    }

    onSubmit = async(e) =>{
        e.preventDefault();
        this.setState({errors:{}})
        
        const user = {
            email: this.state.email,
            password: this.state.password,
            rememberMe: this.state.rememberMe,
        }
        const resp = await new UserService().login(user);
        if(resp.status){
            console.log(resp)
            localStorage.setItem('user',JSON.stringify(resp.user));
            localStorage.setItem('token',resp.token);
            window.location.href = '/'
        }else{
            if(resp.errors){
                this.setState({errors:resp.errors});
            }
        }
    }

    render() {
        return (
            <>
                <DocumentTitle title="Iniciar sesión"/>
                <HeaderSite/>
                <div className="row mx-0">
                    <div className="col-md-6">
                        <div className="container mx-auto mt-5 pt-md-5 pt-0 px-md-5">

                        <div className=' d-flex d-md-none' >
                            <AuthBranding/>
                        </div>

                            <form className="mt-5" onSubmit={this.onSubmit}>
                                <div className="row mb-3">
                                    <div className="col-10 offset-md-1">
                                        <h1 style={{fontSize:"16pt",}} className="mb-4">Utiliza tu correo electrónico para iniciar sesión</h1>
                                        <label htmlFor="email">Correo electrónico</label>
                                        <input type="email" id="email" name="email" onChange={this.onChangeInput} value={this.state.email}  className="form-control" required/>
                                        { this.state.errors && this.state.errors.email && (<small style={{display:"block"}} className="text-danger">{this.state.errors.email}</small>)}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-10 offset-md-1">
                                        <label htmlFor="password">Contraseña</label>
                                        {/* <input type="password" id="password" name="password" onChange={this.onChangeInput} value={this.state.password} className="form-control" required/> */}
                                        <PasswordInput onChange={this.onChangeInput} name='password' id='password' required={true}></PasswordInput>
                                        { this.state.errors && this.state.errors.password && (<small style={{display:"block"}} className="text-danger">{this.state.errors.password}</small>)}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-5 offset-md-1">
                                        <input type="checkbox" id="rememberme" name="rememberMe" onChange={this.onChangeCheck} value={this.state.rememberme} className="btn btn-primary"/> <label htmlFor="rememberme">Mantenerme conectado</label>
                                    </div>
                                    <div className="col-md-5 text-md-right">
                                        <a href="/recovery-password">¿No recuerdas tu contraseña?</a>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6 offset-md-3">
                                        <button type="submit" className="btn btn-primary w-100">Entrar</button>
                                    </div>
                                </div>
                            </form>

                            <p className="text-center mb-3">¿Aún no te has registrado? <Link to="/signup">Hagámoslo ahora</Link></p>

                            <p className="text-center mb-3">Ó conectate con...</p>
                            <div className="row">
                                <div className="col-12 col-md-6 offset-md-3">
                                    {/* <button className="btn btn-danger w-100">
                                        btn btn-danger w-100
                                    </button> */}
                                    <GoogleLoginBtn/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 d-none d-md-flex align-items-center container' style={{ minHeight: "70vh" }} >
                        <AuthBranding/>
                    </div>
                </div>
                
            </>
        )
    }

}